import React from "react";
import "./App.css";
import CheckoutForm from "./CheckoutForm";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import WelcomePage from "./WelcomePage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const domainThemes = [
  {
    domain: "demo2.wr.alquist.nl",
    theme: "alternate",
  },
  // Add more domain-theme mappings as needed
];

const getThemeForDomain = (domain: string) => {
  const matchingTheme = domainThemes.find((dt) => dt.domain === domain);
  return matchingTheme
    ? matchingTheme.theme
    : process.env["REACT_APP_THEME"] || "default";
};

const themeName = getThemeForDomain(window.location.hostname);

const theme = createTheme({
  palette: {
    primary: {
      main: themeName === "default" ? "#2980b9" : "#6029b9",
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className={`theme-${themeName}`}>
          <div className="App">
            <Switch>
              <Route path="/" exact component={WelcomePage} />
              <Route
                path="/checkout1"
                render={(props) => (
                  <CheckoutForm
                    {...props}
                    styleProp={{ background: "yellow" }}
                  />
                )}
              />
              <Route
                path="/checkout2"
                render={(props) => (
                  <CheckoutForm
                    {...props}
                    styleProp={{ background: "lightgreen" }}
                  />
                )}
              />
              <Route path="*/checkout" component={CheckoutForm} />
              <Route path="*/" exact component={WelcomePage} />
            </Switch>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
