import React from "react";
import Card from "./Card";
import "./WelcomePage.css";
import { useHistory, useLocation } from "react-router-dom";

const WelcomePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const cardData = {
    title: "Featured Product",
    description:
      "Discover our amazing featured product for unparalleled quality and design.",
    imageSrc: `${process.env.PUBLIC_URL}/store-image.png`,
    // "https://via.placeholder.com/672x280"
  };

  const handleClick = () => {
    const checkoutPath = `${location.pathname.replace(/\/?$/, '/')}checkout`;
    history.push(checkoutPath);
  };

  return (
    <div className="welcome-page">
      <div className="welcome-content">
        <h1 className="welcome-heading">Welcome to Our Demo 1 Store</h1>
        <p className="welcome-text">
          Explore our amazing products and enjoy a seamless shopping experience.
        </p>
        <Card
          title={cardData.title}
          description={cardData.description}
          imageSrc={cardData.imageSrc}
        />
        <a className="cta-button" onClick={handleClick}>
          Go To Checkout
        </a>
      </div>
    </div>
  );
};

export default WelcomePage;
